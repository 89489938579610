// generated on build time, from script >> iac/scripts/build-environment-conf.sh

export const environment = {
  production: true,
  scraperMock: false,
  env: 'dev',
  baseUrl: 'https://api.dev.belender.net',
  notificationsBaseUrl: 'wss://notifications.dev.belender.net'
};


